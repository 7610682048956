import React, { PropsWithChildren } from "react";
import tw, { TwStyle } from "twin.macro";

interface ButtonProps {
  variant: "outlined" | "contained";
  customStyles?: TwStyle;
  key?: string;
  onClick?: () => void;
  fullWidth?: boolean;
  large?: boolean;
}

const styles = {
  baseStyles: tw`text-center uppercase transition-all duration-300 border-2 cursor-pointer py-2.5 px-10 letter-spacing[1.5px] font-display`,
  outlined: tw`text-raisin bg-transparent border-raisin hover:(bg-raisin text-white)`,
  contained: tw`text-white bg-raisin border-raisin hover:(text-raisin bg-transparent)`,
  large: tw`text-[26px] py-[15px]`,
};

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  variant,
  customStyles,
  large = false,
  fullWidth = false,
  ...rest
}) => {
  return (
    <button
      css={[
        styles.baseStyles,
        variant === "outlined" && styles.outlined,
        variant === "contained" && styles.contained,
        fullWidth && tw`w-full`,
        large && styles.large,
        customStyles,
      ]}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
