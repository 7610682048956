import React, { useState } from "react";
import tw from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button } from ".";
import FacebookLogo from "../../images/svg/facebook.svg";
import InstagramLogo from "../../images/svg/instagram.svg";
import TwitterLogo from "../../images/svg/twitter.svg";
import { toTitleCase } from "../../utils/text-utils";
import { Link } from "gatsby";
import { useHistoryContext } from "../../context/HistoryContext";

const isComingSoon = (openingHours: any) => {
  for (const day in openingHours) {
    if (!openingHours[day].closed) {
      return false;
    }
  }
  return true;
};

const s = {
  arrowBase: tw`h-[3px] absolute block bg-raisin transition-all duration-200`,
};

const LocationCard: React.FC<any> = ({
  expanded = false,
  branch: {
    locationName,
    address: { addressLocality, postalCode, streetAddress, override },
    slug,
    booking_url,
    email,
    telephone,
    googlePlacesId,
    image,
    openingHours,
  },
}) => {
  const [open, setOpen] = useState(false);
  const { handleConversion } = useHistoryContext();
  return (
    <div tw="max-w-screen-xl w-full">
      <div tw="flex flex-col md:flex-row">
        <div tw="h-72 md:(flex-1 h-auto) grid relative">
          <Link to={`/locations/${slug}`}>
            <GatsbyImage
              image={getImage(image.localFile || image)}
              alt={`${locationName} store`}
              loading="lazy"
              tw="h-full w-full grid-area[1/1]"
            />
            <h4 tw="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 grid-area[1/1] z-10 text-white uppercase text-[50px] letter-spacing[3.5px] font-display">
              {locationName}
            </h4>
          </Link>
        </div>
        <div tw="flex-1 bg-honeydew p-8 grid grid-cols-2 relative">
          <div tw="font-light col-span-2 sm:col-span-1 leading-7 mb-7 mr-7">
            <h6 tw="mb-4 font-semibold uppercase font-display letter-spacing[1.5px]">
              ADDRESS
            </h6>
            {override ? (
              <p tw="whitespace-pre-wrap">{override}</p>
            ) : (
              <>
                <p>{streetAddress}</p>
                <p>{addressLocality}</p>
                <p>{postalCode}</p>
              </>
            )}
          </div>
          <div tw="font-light col-span-2 sm:col-span-1 leading-7 mb-7 mr-7">
            <h6 tw="mb-4 font-semibold uppercase font-display letter-spacing[1.5px]">
              GET IN TOUCH
            </h6>
            {!!telephone && (
              <>
                <p>Give us a call</p>
                <p>{telephone}</p>
              </>
            )}
            <p tw="mt-4">Email us at</p>
            <p>
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
                tw="hover:border-current border-transparent border-b-2 transition-all duration-300"
              >
                {email}
              </a>
            </p>
          </div>
          <div tw="font-light col-span-2 sm:col-span-1 leading-7 mb-7 mr-7">
            <h6 tw="mb-4 font-semibold uppercase font-display letter-spacing[1.5px]">
              Opening Hours
            </h6>
            {isComingSoon(openingHours) ? (
              <p>Coming soon...</p>
            ) : (
              <ul>
                {Object.entries(openingHours).map(
                  ([day, { closed, closes, opens }]) => (
                    <li key={day}>{`${toTitleCase(day)}: ${
                      closed
                        ? "Closed"
                        : `${opens.slice(0, 5)} - ${closes.slice(0, 5)}`
                    }`}</li>
                  )
                )}
              </ul>
            )}
          </div>
          <div tw="font-light col-span-2 sm:col-span-1 leading-7 mb-7 mr-7">
            <h6 tw="mb-4 font-semibold uppercase font-display letter-spacing[1.5px]">
              Social
            </h6>
            <div tw="flex space-x-3 mb-8">
              <a
                href="https://www.facebook.com/TheEarPlace/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookLogo tw="fill-current text-raisin cursor-pointer h-7" />
              </a>
              <a
                href="https://www.instagram.com/the_ear_place/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramLogo tw="fill-current text-raisin cursor-pointer h-7" />
              </a>
              <a
                href="https://twitter.com/the_ear_place"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterLogo tw="fill-current text-raisin cursor-pointer h-7" />
              </a>
            </div>
            <a
              href={booking_url || `mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
              tw="hover:border-current border-transparent transition-all duration-300"
              className="book_appt_button book_appt_location_card"
              onClick={() =>
                handleConversion(
                  "book_appt_button book_appt_location_card",
                  `${locationName} card`
                )
              }
            >
              <Button
                variant="outlined"
                fullWidth={false}
                customStyles={tw`px-10 py-2.5 font-display font-semibold uppercase text-base leading-none`}
              >
                Book
              </Button>
            </a>
          </div>
          {!expanded && (
            <div tw="absolute right-12 bottom-12">
              <div
                tw="relative cursor-pointer"
                onClick={() => setOpen((prev) => !prev)}
              >
                <span
                  tw="w-5"
                  css={[
                    s.arrowBase,
                    open
                      ? tw`-translate-x-1/2 -rotate-45 w-8`
                      : tw`w-5 rotate-45 translate-y-2.5 -translate-x-4`,
                  ]}
                />
                <span
                  css={[s.arrowBase, open && tw`opacity-0`]}
                  tw="w-8 rotate-90 -translate-x-1/2"
                />
                <span
                  tw="w-5"
                  css={[
                    s.arrowBase,
                    open
                      ? tw`-translate-x-1/2 rotate-45 w-8`
                      : tw`-rotate-45 translate-y-2.5 -translate-x-1`,
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        tw="w-full h-[280px] md:h-96 max-h-0 transition-all"
        css={[(open || expanded) && tw`max-h-[280px] md:max-h-96`]}
      >
        <iframe
          loading="lazy"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src={`https://www.google.com/maps/embed/v1/place?q=place_id:${googlePlacesId}&key=${process.env.GATSBY_GOOGLE_API_KEY}`}
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default LocationCard;
