import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import qs from "query-string";
import { useLocation } from "@reach/router";

const HistoryContext = createContext<{
  handleConversion: (tags: string, component: string) => void;
}>({
  handleConversion: () => {},
});

const useHistoryContext = () => {
  const context = useContext(HistoryContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a Provider");
  }
  return context;
};

function HistoryContextProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const [crumbs, setCrumbs] = useState<string[]>([]);
  const randomId = useMemo(() => getRandomId(), []);
  const location = useLocation();

  const handleConversion = async (tags: string, component: string) => {
    const initialPageQuery = qs.parse(crumbs[0].split("?")[1] ?? "");
    await fetch(
      "https://europe-west2-omnified-optical.cloudfunctions.net/eap-registerAdConversion",
      {
        method: "POST",
        body: JSON.stringify({
          history: crumbs,
          tags,
          isDevRecord: !process.env.GATSBY_REGISTER_CONVERSION,
          component,
          initialPageQuery,
          randomId,
          isGoogleAd: !!initialPageQuery.gclid || !!initialPageQuery.gad_source,
        }),
      }
    );
  };

  useEffect(() => {
    setCrumbs((prev) => [...prev, location.pathname + location.search]);
  }, [location]);

  return (
    <HistoryContext.Provider
      value={{
        handleConversion,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
}

export { HistoryContextProvider, useHistoryContext };

function getRandomId() {
  const characters = "1234567890abcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  return Array(48)
    .fill(null)
    .map(() => characters.charAt(Math.floor(Math.random() * charactersLength)))
    .join("");
}
