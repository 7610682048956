import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";
import { Link } from ".";
import { footerNavs } from "../config";
import { typography } from "../styles";
import AKLogo from "../images/svg/AK_Hearing_Care_Logo.svg";

const socialNavs = [
  { label: "Facebook", href: "https://www.facebook.com/TheEarPlace/" },
  { label: "Instagram", href: "https://www.instagram.com/the_ear_place/" },
  { label: "Twitter", href: "https://twitter.com/the_ear_place" },
];

const query = graphql`
  query FooterQuery {
    allStrapiBranch {
      edges {
        node {
          locationName
          slug
        }
      }
    }
  }
`;

const s = {
  category: tw`text-xs! xl:text-base! mb-2.5`,
  link: tw`block text-base! xl:text-lg! letter-spacing[1.5px] text-sonic mb-2.5`,
};

const Footer = () => {
  const data = useStaticQuery(query);
  return (
    <footer tw=" border-t-sonic border-t mt-12 md:mt-24">
      <div tw="max-w-screen-xl mx-auto px-4 py-12 md:py-24 xl:px-0">
        <div tw="grid space-y-8 sm:(space-y-0 space-x-8) grid-cols-4">
          <div tw="col-span-4 sm:col-span-1">
            <h6 css={[typography.header, s.category]}>Services</h6>
            <div>
              {footerNavs[0].links.map(({ label, to }) => (
                <Link key={to} to={to} css={[typography.header, s.link]}>
                  {label}
                </Link>
              ))}
            </div>
          </div>
          <div tw="col-span-4 sm:col-span-1">
            <h6 css={[typography.header, s.category]}>Quick Links</h6>
            <div>
              {footerNavs[1].links.map(({ label, to }) => (
                <Link key={to} to={to} css={[typography.header, s.link]}>
                  {label}
                </Link>
              ))}
            </div>
          </div>
          <div tw="col-span-4 sm:col-span-1">
            <h6 css={[typography.header, s.category]}>Locations</h6>
            {data.allStrapiBranch.edges.map(({ node }) => (
              <Link
                to={`/locations/${node.slug}`}
                key={node.slug}
                css={[typography.header, s.link]}
              >
                {node.locationName}
              </Link>
            ))}
          </div>
          <div tw="col-span-4 sm:col-span-1">
            <h6 css={[typography.header, s.category]}>Social</h6>
            {socialNavs.map(({ label, href }) => (
              <a
                key={label}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                css={[typography.header, s.link]}
              >
                {label}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div tw="max-w-screen-xl mx-auto px-4 xl:px-0 flex flex-col space-y-6 md:(flex-row space-y-0) items-center justify-between mt-6 pb-12 lg:pb-24 relative">
        <Link
          to="/"
          tw="md:(absolute left-1/2 -translate-x-1/2 top-0 -translate-y-1/2)"
        >
          <AKLogo tw="w-32" />
        </Link>
        <div>
          <p tw="text-xs font-light">
            {"© The Ear Place by Alex Kemp 2022. "}
            <Link tw="underline" to="/privacy">
              Privacy
            </Link>
          </p>
        </div>
        <p tw="text-xs font-light">
          Website by{" "}
          <a
            href="https://omnified.agency"
            target="_blank"
            rel="noopener noreferrer"
            tw="underline"
          >
            Omnified
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
