exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-ear-lab-tsx": () => import("./../../../src/pages/ear-lab.tsx" /* webpackChunkName: "component---src-pages-ear-lab-tsx" */),
  "component---src-pages-hearing-aid-brands-tsx": () => import("./../../../src/pages/hearing-aid-brands.tsx" /* webpackChunkName: "component---src-pages-hearing-aid-brands-tsx" */),
  "component---src-pages-hearing-assessment-tsx": () => import("./../../../src/pages/hearing-assessment.tsx" /* webpackChunkName: "component---src-pages-hearing-assessment-tsx" */),
  "component---src-pages-hearing-protection-and-noise-reduction-tsx": () => import("./../../../src/pages/hearing-protection-and-noise-reduction.tsx" /* webpackChunkName: "component---src-pages-hearing-protection-and-noise-reduction-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-micro-suction-wax-removal-tsx": () => import("./../../../src/pages/micro-suction-wax-removal.tsx" /* webpackChunkName: "component---src-pages-micro-suction-wax-removal-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-sound-sleep-solutions-tsx": () => import("./../../../src/pages/sound-sleep-solutions.tsx" /* webpackChunkName: "component---src-pages-sound-sleep-solutions-tsx" */),
  "component---src-pages-speech-perception-tsx": () => import("./../../../src/pages/speech-perception.tsx" /* webpackChunkName: "component---src-pages-speech-perception-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-tinnitus-assessment-tsx": () => import("./../../../src/pages/tinnitus-assessment.tsx" /* webpackChunkName: "component---src-pages-tinnitus-assessment-tsx" */),
  "component---src-pages-tympanometry-tsx": () => import("./../../../src/pages/tympanometry.tsx" /* webpackChunkName: "component---src-pages-tympanometry-tsx" */),
  "component---src-pages-video-otoscopy-tsx": () => import("./../../../src/pages/video-otoscopy.tsx" /* webpackChunkName: "component---src-pages-video-otoscopy-tsx" */),
  "component---src-templates-blog-category-page-tsx": () => import("./../../../src/templates/BlogCategoryPage.tsx" /* webpackChunkName: "component---src-templates-blog-category-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-careers-post-tsx": () => import("./../../../src/templates/CareersPost.tsx" /* webpackChunkName: "component---src-templates-careers-post-tsx" */),
  "component---src-templates-legacy-blog-post-tsx": () => import("./../../../src/templates/LegacyBlogPost.tsx" /* webpackChunkName: "component---src-templates-legacy-blog-post-tsx" */),
  "component---src-templates-location-page-tsx": () => import("./../../../src/templates/LocationPage.tsx" /* webpackChunkName: "component---src-templates-location-page-tsx" */)
}

