import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import React from "react";
import { helmetJsonLdProp } from "react-schemaorg";
import { IStrapiBranch } from "../utils/types";
import { Organization, WithContext } from "schema-dts";
import { getLocationData } from "../utils/structured-data";

export interface SEOProps {
  title: string;
  description?: string;
  previewImage?: { src?: string; width?: number };
  lang?: string;
  keywords?: [];
  location?: IStrapiBranch;
}

const orgData: WithContext<Organization> = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "The Ear Place",
  url: "https://earplace.co.uk",
  logo: "https://tep-website-images.s3.amazonaws.com/logo-green.svg",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "+44(0) 1629 339001",
    contactType: "customer service",
    areaServed: "GB",
    availableLanguage: "en",
  },
  sameAs: [
    "https://www.facebook.com/TheEarPlace/",
    "https://www.instagram.com/the_ear_place/",
    "https://twitter.com/the_ear_place",
  ],
};

const SEO: React.FC<SEOProps> = ({
  description = "The Ear Place, Independent Hearing. With locations across England, our audiologists provide a different level of ear and hearing care. Services include hearing assessments, hearing aids, tinnitus, wax removal, and much more.",
  lang = "en",
  keywords = [
    "hearing",
    "audiology",
    "hearing aids",
    "tinnitus",
    "wax removal",
    "hearing test",
    "ear lab",
  ],
  previewImage,
  title,
  location,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            logo
          }
        }
      }
    `
  );

  const structuredDataArray = [helmetJsonLdProp(orgData)];
  if (location) {
    structuredDataArray.push(helmetJsonLdProp(getLocationData(location)));
  }

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const metaPreview = previewImage?.src || site.siteMetadata?.logo;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle} | %s` : undefined}
      meta={[
        {
          content: metaDescription,
          name: "description",
        },
        {
          content: title,
          property: "og:title",
        },
        {
          content: metaDescription,
          property: "og:description",
        },
        {
          content: metaPreview, //previewImagePath,
          property: "og:image",
        },
        {
          content: "website",
          property: "og:type",
        },
        {
          content: "summary",
          name: "twitter:card",
        },
        {
          content: "the_ear_place",
          name: "twitter:creator",
        },
        {
          content: title,
          name: "twitter:title",
        },
        {
          content: metaDescription,
          name: "twitter:description",
        },
        {
          name: "keywords",
          content: keywords.join(", "),
        },
      ]}
      script={structuredDataArray}
    />
  );
};

export default SEO;
