import React from "react";
import "twin.macro";
import { Link as _Link } from "gatsby";

const Link = ({ children, to, ...rest }) => {
  return (
    <_Link {...rest} to={to} tw="text-decoration[none]">
      {children}
    </_Link>
  );
};

export default Link;
