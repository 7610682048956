const earLabSections: {
  header: string;
  text: string;
  to?: string;
  imageKey: string;
}[] = [
  {
    header: "HEARING ASSESSMENT",
    text: "At The Ear Place we pride ourselves on our high level of service and thorough testing procedures. Our advanced hearing assessment includes further checks to provide additional information on both the health and function of the ears.",
    to: "/hearing-assessment",
    imageKey: "Assessment",
  },
  {
    header: "VIDEO OTOSCOPY",
    text: "In order to check the health of the ears visually a piece of equipment called an otoscope is used to allow the audiologist to view your ears under magnified lighting.",
    to: "/video-otoscopy",
    imageKey: "Otoscopy",
  },
  {
    header: "TYMPANOMETRY",
    text: "If a hearing loss is present, a hearing assessment will establish what type of hearing loss it is. However, a hearing test alone does not give information how well the ears are functioning or how well the ear drums are moving. Tympanometry does allow this to be assessed.",
    to: "/tympanometry",
    imageKey: "Tympanometry",
  },
  {
    header: "SPEECH PERCEPTION",
    text: "A hearing test will provide information on which frequency tones can be heard at certain volumes, however, this can be difficult to convert and relate to real life scenarios.",
    to: "/speech-perception",
    imageKey: "Perception",
  },
  {
    header: "TINNITUS ASSESSMENT",
    text: "Tinnitus is not a disease or an illness, it is the term for hearing sounds in the ears or the head which are not from an external sound source. Tinnitus can sound different to different individuals, a few common descriptions of how tinnitus can sound include but are not limited to; ringing, buzzing, whistling, humming, ticking or hissing.",
    to: "/tinnitus-assessment",
    imageKey: "Tinnitus",
  },
  {
    header: "MICRO SUCTION WAX REMOVAL",
    text: "Micro suction is the preferred and safest method of removing wax build up or blockages from the ear canals. Wax is a natural substance which helps lubricate the ear canal and protect the ear from foreign bodies, however, difficulties can arise if the normal and natural migration of the wax out of the ear canal is impeded.",
    to: "/micro-suction-wax-removal",
    imageKey: "Micro",
  },
  {
    header: "HEARING PROTECTION / NOISE REDUCTION",
    text: "At The Ear Place not only do we diagnose and treat hearing loss but we can also assist in preventing or delaying the onset of hearing loss in the first place or making certain activities more comfortable and enjoyable.",
    to: "/hearing-protection-and-noise-reduction",
    imageKey: "Protection",
  },
  {
    header: "SOUND SLEEP SOLUTIONS",
    text: `We offer custom made ear moulds for noise reduction against things such as snoring, traffic noise and air conditioning units - these plugs are specially designed to ensure they do not hinder your comfort when sleeping, they are small, soft and made to the shape of your ears for comfort whilst blocking out unwanted noise whilst sleeping and resting.<br /><br />Custom made ear moulds are also available for individuals who grind their teeth at night which can lead to teeth issues, nearby joints problems as well as causing general discomfort in the mouth and head.`,
    imageKey: "Sleep",
    to: "/sound-sleep-solutions",
  },
];

export default earLabSections;
