import { IStrapiBranch } from "./types";
import { WithContext, Optician } from "schema-dts";
import { toTitleCase } from "./text-utils";

export const getLocationData = (strapiBranch: IStrapiBranch) => {
  const { locationName, telephone, address, image, slug, openingHours } =
    strapiBranch;
  const url = `https://earplace.co.uk/locations/${slug}`;
  const data: WithContext<Optician> = {
    "@context": "https://schema.org",
    "@type": "Optician",
    name: `The Eye Place - ${locationName}`,
    image: [image.url],
    logo: "https://tep-website-images.s3.amazonaws.com/logo-green.svg",
    "@id": url,
    url,
    telephone,
    address: {
      "@type": "PostalAddress",
      streetAddress: address.streetAddress,
      addressLocality: address.addressLocality,
      addressRegion: address.addressRegion,
      postalCode: address.postalCode,
      addressCountry: address.addressCountry,
    },
    openingHoursSpecification: Object.entries(openingHours).map(
      ([dayOfWeek, { closed, closes, opens }]) => ({
        "@type": "OpeningHoursSpecification",
        dayOfWeek: toTitleCase(dayOfWeek),
        opens: closed ? "00:00" : opens.substring(0, 5),
        closes: closed ? "00:00" : closes.substring(0, 5),
      })
    ),
  };
  return data;
};
