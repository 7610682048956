import React from "react";
import tw from "twin.macro";

const styles = {
  burgerSpan: (open: boolean, opaque: boolean) => [
    tw`absolute block transition-all duration-500 ease-in-out h-0.5 w-7`,
    open && tw`bg-honeydew`,
    !opaque && !open && tw`bg-white delay-1000`,
    opaque && !open && tw`bg-sonic duration-100`,
  ],
};

const BurgerMenu: React.FC<{
  open: boolean;
  opaque: boolean;
  handleClick: () => void;
}> = ({ open, handleClick, opaque }) => {
  return (
    <button
      tw="relative z-50 w-10 h-10 bg-transparent focus:outline-none"
      onClick={handleClick}
    >
      <span tw="sr-only">Open main menu</span>
      <div tw="absolute block transform -translate-x-1/2 -translate-y-1/2 w-7 left-1/2 top-1/2">
        <span
          aria-hidden="true"
          css={[
            styles.burgerSpan(open, opaque),
            open ? tw`rotate-45` : tw`-translate-y-2.5`,
          ]}
        />
        <span
          aria-hidden="true"
          css={[styles.burgerSpan(open, opaque), open && tw`opacity-0`]}
        />
        <span
          aria-hidden="true"
          css={[
            styles.burgerSpan(open, opaque),
            open ? tw`-rotate-45` : tw`translate-y-2.5`,
          ]}
        />
      </div>
    </button>
  );
};

export default BurgerMenu;
