export const toTitleCase = (phrase: string): string => {
  return phrase
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getSnippetFromHtml = (html: string) => {
  if (typeof document === "undefined") return "";
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;
  const text = tempElement.textContent || tempElement.innerText;
  return text.substring(0, 100).split(".").join(". ") + "...";
};
