const navs: { label: string; to: string }[] = [
  { label: "THE EAR LAB", to: "/ear-lab" },
  { label: "HERE & NOW", to: "/blog" },
  { label: "HEARING AID BRANDS", to: "/hearing-aid-brands" },
  { label: "TINNITUS ASSESSMENT", to: "/tinnitus-assessment" },
  { label: "MICRO-SUCTION WAX REMOVAL", to: "/micro-suction-wax-removal" },
  {
    label: "HEARING PROTECTION",
    to: "/hearing-protection-and-noise-reduction",
  },
  { label: "SOUND SLEEP SOLUTIONS", to: "/sound-sleep-solutions" },
  { label: "GET IN TOUCH", to: "/locations" },
  { label: "Careers at the Ear Place", to: "/careers" },
  { label: "Our Team", to: "/our-team" },
];

export default navs;
