import React, { PropsWithChildren } from "react";
import "twin.macro";
import { typography } from "../../styles";

interface IBlankPage {
  ariaLabel: string;
  header?: string;
  intro?: string;
}

const BlankPage: React.FC<PropsWithChildren<IBlankPage>> = ({
  children,
  header,
  intro,
  ariaLabel,
}) => {
  return (
    <section aria-label={ariaLabel}>
      <div tw="max-w-screen-xl px-4 mx-auto ">
        {header && <h1 css={[typography.pageHeader]}>{header}</h1>}
        {intro && (
          <p
            tw="max-w-4xl mx-auto mb-16 text-xl font-light text-center"
            dangerouslySetInnerHTML={{ __html: intro }}
          />
        )}
        {children}
      </div>
    </section>
  );
};

export default BlankPage;
