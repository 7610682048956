const footerNavs = [
  {
    section: "Services",
    links: [
      { label: "Hearing Aids & Brands", to: "/hearing-aid-brands" },
      { label: "Tinnitus Assessment", to: "/tinnitus-assessment" },
      { label: "Hearing Assessment", to: "/hearing-assessment" },
      { label: "Micro-Suction Wax Removal", to: "/micro-suction-wax-removal" },
      {
        label: "Hearing Protection",
        to: "/hearing-protection-and-noise-reduction",
      },
      { label: "Sound Sleep Solutions", to: "/sound-sleep-solutions" },
    ],
  },
  {
    section: "Quick links",
    links: [
      { label: "The Ear Lab", to: "/ear-lab" },
      { label: "Here & Now", to: "/blog" },
      { label: "Get In Touch", to: "/locations" },
      { label: "Our Team", to: "/our-team" },
      { label: "Careers at the Ear Place", to: "/careers" },
      { label: "Privacy", to: "/privacy" },
      { label: "Terms & Conditions", to: "/terms-and-conditions" },
    ],
  },
  { section: "Locations" },
  { section: "Social" },
];

export default footerNavs;
