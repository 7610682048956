import React from "react";
import tw, { css } from "twin.macro";

const styles = css({
  h4: tw`font-display! text-3xl uppercase letter-spacing[0.3px] font-normal`,
});

const ProseWrapper: React.FC = ({ children }) => {
  return (
    <div tw="prose mx-auto font-light" css={[styles]}>
      {children}
    </div>
  );
};

export default ProseWrapper;
