import "@fontsource/roboto/300.css";
import "@fontsource/roboto/500.css";
import "@fontsource/barlow-condensed/400.css";
import "@fontsource/barlow-condensed/600.css";
import "../styles/global.css";

import * as React from "react";
import tw, { GlobalStyles, css } from "twin.macro";
import { Global } from "@emotion/react";
import Footer from "./Footer";
import { Navbar } from ".";
import { PageProps } from "gatsby";
import { HistoryContextProvider } from "../context/HistoryContext";

const layer1 = css({
  html: tw`antialiased`,
  WebkitTapHighlightColor: "transparent",
});

const Layout: React.FC<PageProps> = (props) => {
  const { children, location } = props;
  return (
    <>
      <GlobalStyles />
      <Global styles={layer1} />
      <HistoryContextProvider>
        {["/", "/ear-lab", "/hearing-aid-brands"].includes(
          location.pathname
        ) ? (
          children
        ) : (
          <>
            <Navbar opaque />
            <main tw="mt-[60px] sm:mt-[100px]">{children}</main>
          </>
        )}
      </HistoryContextProvider>
      <Footer />
    </>
  );
};

export default Layout;
