const hearingAidFeatures = [
  {
    label: "RECHARGEABLE",
    text: "Rechargeable hearing aids have several benefits. This function means you do not have to carry batteries around with you, one charge overnight will ensure your hearing aids will last you for the entire following day and not having to change batteries makes the hearing aids easier to handle with less maintenance.",
    imageKey: "Rechargeable",
  },
  {
    label: "BLUETOOTH",
    text: "This feature means your hearing aids can now connect directly to your phone, tv and other Bluetooth enabled devices allowing you a premium listening experience by having the tv, music or voice streamed directly to your ears via your hearing aids.",
    imageKey: "Bluetooth",
  },
  {
    label: "PHONE COMPATIBLE",
    text: "Apps are available to download onto most smartphones in order to transform your phone into a remote control for your hearing aids, allowing you to be able to easily adjust settings such as the volume yourself, the bass and treble and even how the microphones work and what direction they focus in.",
    imageKey: "Compatible",
  },
  {
    label: "ITTT",
    text: "(If this then that) compatible devices - hearing aids can now link with certain other 'ITTT' smart appliances which can enable features such as: IF your hearing aid battery needs changing THEN a text can be sent to a relative. IF your doorbell rings THEN you will receive an alert via your heading aids and many more useful combinations.",
    imageKey: "Ittt",
  },
  {
    label: "FIND MY HEARING AID",
    text: "With this function you can now locate your hearing aids if misplaced by using the ‘find my hearing aids’ feature via a phone app.",
    imageKey: "Find",
  },
  {
    label: "CALL-TO-TEXT ABILITY",
    text: "This allows the stress to be taken out of phone calls, especially with unfamiliar voices, this gives the ability to read what the person on the phone is saying on the screen of your phone in real time, so you never interpret things wrong and never forget what has been said with the ‘save transcript’ function available.",
    imageKey: "Call",
  },
  {
    label: "ACTIVE LEARNING",
    text: "Many hearing aids have the function for the wearer to adjust certain settings such as the volume. Now hearing aids can remember when this adjustment is made, if the wearer always turns the volume down when they are in a noisy environment, the hearing aids will eventually remember this, and when they detect that they are in a noisy environment they will make the adjustment automatically.",
    imageKey: "Active",
  },
];

export default hearingAidFeatures;
