import React from "react";
import tw from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import Link from "./Link";
import { typography } from "../../styles";

const query = graphql`
  query {
    allStrapiBranch {
      edges {
        node {
          locationName
          cityOrCounty
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 600
                  outputPixelDensities: [0.1, 0.25, 0.5, 1]
                )
              }
            }
          }
        }
      }
    }
  }
`;

const s = {
  // firstItem: tw`col-span-full lg:(col-span-3 row-span-2) h-32 xs:h-40 md:h-auto max-h-[500px] lg:max-h-full`,
  otherItems: tw`col-span-full md:col-span-6 lg:col-span-3 h-32 xs:h-40 md:h-auto`,
};

const LocationBlock = () => {
  const { allStrapiBranch } = useStaticQuery(query);
  return (
    <div>
      <h3 tw="text-center my-14" css={[typography.header]}>
        Our locations
      </h3>
      <div tw="grid grid-cols-12 lg:grid-rows-2 md:(gap-8 mx-8)">
        {allStrapiBranch.edges
          .sort((a, b) =>
            b.node.cityOrCounty.localeCompare(a.node.cityOrCounty)
          )
          .map(({ node: { locationName, cityOrCounty, image, slug } }) => (
            <div css={[s.otherItems]} key={locationName}>
              <Link to={`/locations/${slug}`}>
                <div tw="grid text-white text-center h-full w-full bg-black/50">
                  <GatsbyImage
                    image={
                      getImage(image.localFile || image) as IGatsbyImageData
                    }
                    alt={`${locationName} store image`}
                    tw="grid-area[1/1]"
                    loading="lazy"
                  />
                  <div tw="grid-area[1/1] relative grid place-items-center bg-black/30">
                    <div tw="flex flex-col justify-center items-center space-y-2 p-4 font-display uppercase">
                      <h2 tw="my-0 text-3xl letter-spacing[0.15em]">
                        {locationName}
                      </h2>
                      <h4 tw="my-0 text-lg">{cityOrCounty}</h4>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default LocationBlock;
